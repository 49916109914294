import React, { Component } from "react";
import { connect } from 'react-redux';

import { ReactReader } from "./modules";





import {
  Container,
  ReaderContainer,
} from "./Components";

const storage = global.localStorage || null;

const DEMO_URL =
  "https://mega.nz/folder/JZhS3abC#gXVYldqfSq_SKbAeU_ebFg/file/gZRzGILQ";
const DEMO_NAME = "Alice in wonderland";

class Preview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullscreen: false,
      location:
        storage && storage.getItem("epub-location")
          ? storage.getItem("epub-location")
          : 2,
      localFile: null,
      localName: null,
      largeText: 1,
      flow: 'paginated',
      highlighted: false,
      book: {},
      library: false,
      bookMark: [],
      isSignIn:true,
    };
    this.rendition = null;


  }
  componentWillMount() {
    if (this.props != null && this.props.location.state != null && this.props.location.state.book) {


      //console.log(this.props.location.state.book)
      this.setState({
        book: this.props.location.state.book

      })
    }
    else if (this.props != null && this.props.location.state != null && this.props.location.state.libbook) {


      //console.log(this.props.location.state.book)
      this.setState({
        book: this.props.location.state.libbook,
        library: true

      })
    }

  }
  componentDidMount() {
   
  }

  createRatting = (ratting) => {
    if (!this.props.user?.User_ID){
      this.setState({
        isSignIn: false
      })
    }else{
      this.setState({isSignIn: false})
    this.props.postRatting({
      'Book_ID': this.state.book?.Book_ID,
      'Ratting': ratting,
  })
      .then((res) => {
         console.log(res)
         return
      })
      .catch((err) => {
          console.log(err)

      })
    }
  }
  toggleHighlight = () => {
    console.log(this.state.highlighted)
    this.setState({
      highlighted: !this.state.highlighted
    });
  };
  toggleFullscreen = () => {
    this.setState(
      {
        fullscreen: !this.state.fullscreen
      },
      () => {
        setTimeout(() => {
          const evt = document.createEvent("UIEvents");
          evt.initUIEvent("resize", true, false, global, 0);
        }, 1000);
      }
    );
  };

  onLocationChanged = location => {

    

    if (storage.getItem("epub-location") === location) {
      console.log("here")
    }

    this.setState(
      {
        location
      },
      () => {
        storage && storage.setItem("epub-location", location);
      }
    );
  };

  onToggleFontSize = () => {
    //const nextState = !this.state.largeText;

    this.setState((prevState) => (
      {
        largeText: (prevState.largeText + 1) % 3
      }),
      () => {
        console.log(this.state.largeText)
        this.rendition.themes.fontSize(this.state.largeText === 1 ? "100%" :this.state.largeText === 2 ? "140%" : "180%");
      }
    );
  };

  onToggleLayout = () => {
    //const flow = !this.state.flow;

    this.rendition.themes.register({ "light": { "body": { "color": "purple" } } });

  };


  onHihlight = (cfiRange, contents) => {
    this.rendition.annotations.highlight(cfiRange, {}, (e) => {
      console.log("highlight clicked", e.target);
    });
    contents.window.getSelection().removeAllRanges();

  }
  getRendition = rendition => {
    // Set inital font-size, and add a pointer to rendition for later updates
    const { largeText } = this.state;
    this.rendition = rendition;
    rendition.themes.fontSize(largeText ? "140%" : "100%");
    if (this.state.highlighted) {
      this.rendition.on('selected', this.onHihlight.bind(this));
      this.rendition.on("selected", (cfiRange, contents) => this.onHihlight(cfiRange, contents));

      console.log('Text : ', this.rendition)
      this.rendition.on("selected", function (cfiRange) {

        this.book.getRange(cfiRange).then(function (range) {
          var text;
          if (range) {
            text = range.toString();
            console.log(text)
          }
        })

      });
    }

  };
  

  updateBook = (book) => {
    this.props.getAllBooks().then((res) => {
      console.log(book)
      if (res.status == true) {
        this.setState({
          bookList: res.content.filter(el => el.Book_ID != book.Book_ID),
          book: book
        }
          // ,() => {
          //   console.log(book)
          //   this.setState({book:book})
          // }
        )

      }
      else {
        alert(res)
      }
    }).catch((err) => {
      console.log(err)

    })
  }
  onClickBack = (book) => {
    this.props.history.push("/aboutbook", { book: book })
  }
  handleChangeFile = (event, results) => {
    if (results.length > 0) {
      const [e, file] = results[0];
      if (file.type !== "application/epub+zip") {
        return alert("Unsupported type");
      }
      this.setState({
        localFile: e.target.result,
        localName: file.name,
        location: null
      });
    }
  };
  render() {
    const { fullscreen, location, localFile, localName, book, library } = this.state;
    return (
      <>
        <Container>
          <ReaderContainer fullscreen={true}>
            
          
            <ReactReader
            createRatting={this.createRatting}
              url={library ? book.Url : book.Url}
              title={book.Name}
              location={location}
              locationChanged={this.onLocationChanged}
              getRendition={this.getRendition}
              onToggleFontSize={this.onToggleFontSize}
              onToggleLayout={this.onToggleLayout}
              toggleHighlight={this.toggleHighlight}
              direction={book.Language === 'English' ? "ltr" : "rtl"}

              book={book}
              user={this.props.user}
              history={this.props.history}
              largeText={this.state.largeText}
              updateBook={this.updateBook}
            />
            {/* <FontSizeButton onClick={this.onToggleFontSize}>
            Toggle font-size
          </FontSizeButton> */}
          </ReaderContainer>
          <div className="footer_bottom footerbtm vertical_center">
            <p className="m-0 poppins_light footertextt">
              2020 The Little Book Company  All rights reserved.                  </p>

          </div>
          {/* <div>
               <img className="back_btn" src={back_btn} onClick={()=> this.onClickBack(book)}></img><br></br>

               </div> */}

        </Container>
      </>
    );
  }
}

const mapStatetoProps = ({ auth }) => ({
  user: auth.user
})
const mapDispatchToProps = ({
 
})
Preview.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(Preview);