import React, { Component } from 'react';
import { connect } from 'react-redux';


import EasyPaisaLogo from '../../assets/images/easypaisalogo.svg'



import moment from 'moment';


const aesjs = require("aes-js");

// I generate the UID from two parts here 
// to ensure the random number provide enough bits.
var firstPart = (Math.random() * 46656) | 0;
var secondPart = (Math.random() * 46656) | 0;
firstPart = ("000" + firstPart.toString(36)).slice(-3);
secondPart = ("000" + secondPart.toString(36)).slice(-3);
const id = firstPart + secondPart;
const hashKey = "A409I4LJZI51OSW9";
const storeId = "73507";
const tokenExpiry = "20211231 111111"
const orderId = id;
const transactionAmount = ""
//const transactionAmount = localStorage.getItem('Price')

const transactionType = "InitialRequest"
const expiryToken = "20211231%20111111"

const postBackURL = "https://littlebookcompany.net/statuseasypay"
//const postBackURL= "http://localhost:3000/statuseasypay"


const timeStamp = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
const sampleString = `amount=${transactionAmount}&expiryDate=${tokenExpiry}&orderRefNum=${orderId}&paymentMethod=InitialRequest&postBackURL=${postBackURL}&storeId=${storeId}&timeStamp=${timeStamp}`

const getEncryptedData = () => {
    console.log(sampleString)
    const keyBuffer = aesjs.utils.utf8.toBytes(hashKey);
    const inputBuffer = aesjs.padding.pkcs7.pad(aesjs.utils.utf8.toBytes(sampleString));
    const escEcb = new aesjs.ModeOfOperation.ecb(keyBuffer);
    const encryptedBytes = escEcb.encrypt(inputBuffer);
    const encryptedData = Buffer.from(encryptedBytes).toString('base64');
    return encryptedData
}
var Iframe = ({ str }) => {
    console.log(str)
    console.log(encodeURIComponent(str))
    const urlStr = `storeId=${storeId}&orderId=${orderId}&transactionAmount=${transactionAmount}&mobileAccountNo=&emailAddress=&transactionType=InitialRequest&tokenExpiry=${expiryToken}&bankIdentificationNumber=&merchantPaymentMethod=&postBackURL=${encodeURIComponent(postBackURL)}&signature=&encryptedHashRequest=${encodeURIComponent(str)}`

    console.log(timeStamp)
    console.log(urlStr)

    return (
        <div>

            <iframe id="easypay-iframe" name="easypay-iframe" className="show-iframe" src={`https://easypay.easypaisa.com.pk/tpg/?${urlStr}`} width="100%"
                height="500px"></iframe>
        </div>
    )
}

class ConfirmPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverError: {},
            isLoading: false,
            book: {},
            Order_ID: "",
            account_no: "",
            Order: {},
            isOrderCreated: 0,
            easyPayError: "",
            encryptedString: this.getEncryptedData(),
            isEasyPay: true,
            sum: 0,
            cart: [],
            Order_ID:"",

        };
    }

    getOrderID = () => {
        var firstPart = (Math.random() * 46656) | 0;
        var secondPart = (Math.random() * 46656) | 0;
        firstPart = ("000" + firstPart.toString(36)).slice(-3);
        secondPart = ("000" + secondPart.toString(36)).slice(-3);
        const id = firstPart + secondPart;
        this.setState({Order_ID: id})
        //return id
    }

    getEncryptedData = () => {
        // I generate the UID from two parts here 
        // to ensure the random number provide enough bits.
        // var firstPart = (Math.random() * 46656) | 0;
        // var secondPart = (Math.random() * 46656) | 0;
        // firstPart = ("000" + firstPart.toString(36)).slice(-3);
        // secondPart = ("000" + secondPart.toString(36)).slice(-3);
        // const id = firstPart + secondPart;
        const hashKey = "A409I4LJZI51OSW9";
        const storeId = "73507";
        const tokenExpiry = "20211231 111111"
        const orderId = this?.props?.location?.state?.Order_ID;
        //const transactionAmount = this?.props?.location?.state?.sum+".0"
        //const transactionAmount = localStorage.getItem('Price')
        const transactionAmount = "50.0"
        const transactionType = "InitialRequest"
        const expiryToken = "20211231%20111111"

        const postBackURL = "https://littlebookcompany.net/statuseasypay"
        //const postBackURL= "http://localhost:3000/statuseasypay"


        const timeStamp = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
        const sampleString = `amount=${transactionAmount}&expiryDate=${tokenExpiry}&orderRefNum=${orderId}&paymentMethod=InitialRequest&postBackURL=${postBackURL}&storeId=${storeId}&timeStamp=${timeStamp}`

        console.log(sampleString)
        const keyBuffer = aesjs.utils.utf8.toBytes(hashKey);
        const inputBuffer = aesjs.padding.pkcs7.pad(aesjs.utils.utf8.toBytes(sampleString));
        const escEcb = new aesjs.ModeOfOperation.ecb(keyBuffer);
        const encryptedBytes = escEcb.encrypt(inputBuffer);
        const encryptedData = Buffer.from(encryptedBytes).toString('base64');
        return encryptedData
    }

     Iframe = ( str ) => {
        //const id = firstPart + secondPart;
        const hashKey = "A409I4LJZI51OSW9";
        const storeId = "73507";
        const tokenExpiry = "20211231 111111"
        const orderId = this?.props?.location?.state?.Order_ID;
        //const transactionAmount = this?.props?.location?.state?.sum+".0"
       const transactionAmount = "50.0"
        //const transactionAmount = localStorage.getItem('Price')

        const transactionType = "InitialRequest"
        const expiryToken = "20211231%20111111"

        const postBackURL = "https://littlebookcompany.net/statuseasypay"
        //const postBackURL= "http://localhost:3000/statuseasypay"


        const timeStamp = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
        //console.log(str)
        //console.log(encodeURIComponent(str))
        const urlStr = `storeId=${storeId}&orderId=${orderId}&transactionAmount=${transactionAmount}&mobileAccountNo=&emailAddress=&transactionType=InitialRequest&tokenExpiry=${expiryToken}&bankIdentificationNumber=&merchantPaymentMethod=&postBackURL=${encodeURIComponent(postBackURL)}&signature=&encryptedHashRequest=${encodeURIComponent(str)}`
    
        //console.log(timeStamp)
        //console.log(urlStr)
    
        return (
            <div>
    
                <iframe id="easypay-iframe" name="easypay-iframe" className="show-iframe" src={`https://easypay.easypaisa.com.pk/tpg/?${urlStr}`} width="100%"
                    height="500px"></iframe>
            </div>
        )
    }

    componentDidMount() {
        //console.log(orderId)

        
        // if (!this.props.user?.User_ID)
        //     this.props.history.push("/login")
        // this.props.postOrder({
        //     'Order_ID': this?.props?.location?.state?.Order_ID,
        //     //'Book_ID': this.state.book?.Book_ID,
        //     'Payment_Method': 'Easy_paisa',
        //     'Payment_Status': 'Pending',
        //     'Amount': this.state.sum,
        //     'books': this.state.cart
        // }).then((response) => {
        //     console.log(response)
        //     if (response.status) {
        //         // this.setState({
        //         //     Order: response.content[0],
        //         // })
        //         const data = this?.props?.location?.state?.cart
        //         localStorage.setItem('Book_ID', JSON.stringify(data));
        //         return



        //     }
        //     else {
        //         return
        //     }
        // }).catch((err) => {
        //     console.log(err)

        // })
    }

    componentWillMount() {
        //setAmount(this?.props?.location?.state?.sum)
        this.getOrderID()
        if (this?.props?.location?.state?.book) {


            //console.log(this.props.location.state.book)
            this.setState({
                book: this.props.location.state.book

            })
        }

        if (this?.props?.location?.state?.cart) {


            //console.log(this.props.location.state.book)
            this.setState({
                cart: this.props.location.state.cart

            })
        }
        if (this?.props?.location?.state?.sum) {


            //console.log(this.props.location.state.book)
            this.setState({
                sum: this.props.location.state.sum

            })
        }

    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })

    }

    onClickPayment = () => {
        console.log('here')
        this.setState({ isLoading: true })
        var Order_ID = Math.floor(Math.random() * 900000) + 100000
        this.props.postOrder({
            'Order_ID': Order_ID,
            'Book_ID': this.state.book?.Book_ID,
            'Payment_Method': 'Easy_paisa',
            'Payment_Status': 'Pending',
        }).then((response) => {
            console.log(response)
            if (response.status) {
                this.setState({
                    Order: response.content[0],
                })
                this.props.getEasyPayMbl({
                    'orderId': '445',
                    'storeId': '73507',
                    'transactionAmount': this.state.book?.Price,
                    'transactionType': 'MA',
                    'mobileAccountNo': this.state.account_no,
                    'emailAddress': 'haseeb@gmail.com',
                }).then((res) => {
                    console.log(res)
                    if (res.responseCode == "0000") {
                        this.setState({
                            isOrderCreated: 1,

                        })

                        this.props.updateOrder({
                            'Book_ID': this.state.book?.Book_ID,
                            'Payment_Method': 'Easy_paisa',
                            'Payment_Status': 'Cleared',
                            'Order_ID': response.content[0]?.Order?.Order_ID,
                            'Reference_No': res.transactionId,
                        }).then((res) => {

                            this.props.putBookInLibrary({
                                'Book_ID': this.state.book?.Book_ID,
                            }).then((res) => {

                            })
                                .catch((err) => {
                                    console.log(err)

                                })
                            console.log(res)
                            if (res.status) {
                                console.log(res)
                                this.setState({ isLoading: false })
                                this.props.history.push("/library")
                                return

                            }
                            else {
                                return
                            }
                        }).catch((err) => {
                            console.log(err)

                        })

                        return

                    }
                    else {
                        this.setState({
                            isOrderCreated: 2,
                            easyPayError: res.responseDesc
                        })
                        this.props.updateOrder({
                            'Book_ID': this.state.book?.Book_ID,
                            'Payment_Method': 'Easy_paisa',
                            'Payment_Status': 'Failed',
                            'Order_ID': response.content[0]?.Order?.Order_ID,
                        }).then((res) => {
                            console.log(res)
                            if (res.status) {
                                this.setState({ isLoading: false })

                                console.log(res)

                                return

                            }
                            else {
                                return
                            }
                        }).catch((err) => {
                            console.log(err)

                        })

                        return


                    }
                }).catch((err) => {
                    console.log(err)

                })

                return

            }
            else {
                return
            }
        }).catch((err) => {
            console.log(err)

        })
    }
    onCardPay = () => {
        const formdata = new FormData()
        formdata.append("storeId", "73507")
        formdata.append("amount", "1")
        formdata.append("postBackURL", "http://localhost:3000/confirmpayment")
        formdata.append("orderRefNum", "1")
        formdata.append("merchantHashedReq", "73507")
        formdata.append("paymentMethod", "CC_PAYMENT_METHOD")




        console.log(formdata)
        this.props.easyPayViaCard(formdata)
    }
    render() {
        const { t, i18n, location, user } = this.props
        const { isLoading, book } = this.state;

        if (this.state.isEasyPay) {
            return (
                this.Iframe(this.state.encryptedString)
                //<Iframe str={this.state.encryptedString} />
            )
        }
        return (
            <div class="dashboard-cotainer">

          </div>
        )
    }

}
const mapStatetoProps = ({ auth }) => ({
    user: auth.user
})
const mapDispatchToProps = ({
   
})
ConfirmPayment.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(ConfirmPayment);

