export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const OPEN_SIDEBAR = 'OPEN_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_ERRORS = 'GET_ERRORS';
 

export const SELECT_RESTAURANT_LOADING   = 'CREATE_RESTAURANT_LOADING';
export const SELECT_RESTAURANT           = 'CREATE_RESTAURANT';
export const SELECT_RESTAURANT_ERROR    = 'CREATE_RESTAURANT_ERROR';
 
export const FETCH_RESTAURANT_LOADING   = 'FETCH_RESTAURANT_LOADING';
export const FETCH_RESTAURANT           = 'FETCH_RESTAURANT';
export const FETCH_RESTAURANT_ERROR     = 'FETCH_RESTAURANT_ERROR';

export const FETCH_ITEM_LOADING   = 'FETCH_ITEM_LOADING';
export const FETCH_ITEM           = 'FETCH_ITEM';
export const FETCH_ITEM_ERROR     = 'FETCH_ITEM_ERROR';
 

export const DELETE_RESTAURANT_LOADING   = 'DELETE_RESTAURANT_LOADING';
export const DELETE_RESTAURANT           = 'DELETE_RESTAURANT';
export const DELETE_RESTAURANT_ERROR     = 'DELETE_RESTAURANT_ERROR';

export const DELETE_ITEM_LOADING   = 'DELETE_ITEM_LOADING';
export const DELETE_ITEM           = 'DELETE_ITEM';
export const DELETE_ITEM_ERROR     = 'DELETE_ITEM_ERROR';