
import React, { Component } from 'react';

import { connect } from 'react-redux';

import searchicon from '../../assets/images/Managebooks/searchicon.svg'
import Polygon from '../../assets/images/Managebooks/Polygon.svg'
import Box from '@mui/material/Box'
import Moment from 'react-moment';

import { getAllOrder, sortOrderByBook, sortOrderByOrder, searchBook, getTotalEarning, getTotalPending } from '../../store/actions/orderAction';
import LottieAnimation from '../../components/Lottie/lottieAnimation';
import DataTable from '../../components/DataTable/DataTable';
import { ConsoleView } from 'react-device-detect';








class SellingHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderList: [],
            errors: {},
            serverError: {},
            isLoading: false,
            sortByName: false,
            sortByDate: false,
            sortByStatus: false,
            sortByReference: false,
            sortByPrice: false,
            search: '',
            totalearning: '',
            pendingTotal: '',
            dayEarning: '',
            dayPending: '',
            currentPage: 1,
            todosPerPage: 10,
            OrderListFiltered: [],
            fromDate: '2021-05-01T01:10:00Z',
            toDate: '2021-10-01T01:10:00Z',
            earning: 0,
            cleared: 0,
            pending: 0

        };
        this.handleChange = this.handleChange.bind(this);

    }

    componentDidUpdate(prevProps, prevState, snapshot) {


    }




    componentDidMount() {
        this.setState({ isLoading: true })
        this.props.getAllOrder().then((res) => {
            console.log(res.content)
            if (res.status) {
                this.setState({
                    orderList: res.content,
                    isLoading: false,
                }, () => {
                    this.getTotalEarning(res.content)
                    this.setState({ ["SORT" + 'createdAt']: 'DESC' })
                    let { orderList, OrderListFiltered } = this.state
                    let orderListSorted = orderList.sort(this.sortArrByOrder('createdAt', 'DESC'))
                    let OrderListFilteredSorted = OrderListFiltered.sort(this.sortArrByOrder('createdAt', 'DESC'))
                    this.setState({ orderList: orderListSorted, OrderListFiltered: OrderListFilteredSorted })
                })

            }
            else {
                alert(res)
            }
        }).catch((err) => {
            console.log(err)

        })

        this.props.getTotalEarning(7).then((res) => {
            console.log(res.content)
            if (res.content[0]?.order_book?.total_amount) {
                console.log('here')
                this.setState({
                    totalearning: res.content[0]?.order_book?.total_amount,
                })

            }
            else {
                // alert(res)
                this.setState({
                    totalearning: "",
                })

            }
        }).catch((err) => {
            console.log(err)

        })

        this.props.getTotalPending(7).then((res) => {
            console.log(res.content)
            if (res.content[0]?.order_book?.total_amount) {
                this.setState({
                    pendingTotal: res.content[0]?.order_book?.total_amount,
                })

            }
            else {
                //alert(res)
                this.setState({
                    pendingTotal: '',
                })
            }
        }).catch((err) => {
            console.log(err)

        })

    }

    onPressSortByBook = (colName, sort) => {

        this.props.sortOrderByBook(colName, sort).then((res) => {
            console.log(res.content)
            if (res.status == true) {

                if (colName == "Name") {
                    this.setState({
                        orderList: res.content,
                        sortByName: !this.state.sortByName
                    })
                } else if (colName == "Price") {
                    this.setState({
                        orderList: res.content,
                        sortByPrice: !this.state.sortByPrice
                    })
                }

            }
            else {
                alert(res)
            }
        }).catch((err) => {
            console.log(err)

        })

    }

    // getValue = (item, prop) => {
    //     switch (prop) {
    //       case "createdAt":
    //         return item.order_book.createdAt;
    //       default:
    //         return item[prop];
    //     }
    //   };
    //   sortArrByOrder = (prop, order) => {
    //     if (order === "ASC")
    //       return (a, b) => {
    //         if (this.getValue(a, prop) > this.getValue(b, prop)) {
    //           return 1;
    //         } else if (this.getValue(a, prop) < this.getValue(b, prop)) {
    //           return -1;
    //         }
    //         return 0;
    //       };

    //     return (a, b) => {
    //       if (this.getValue(a, prop) < this.getValue(b, prop)) {
    //         return 1;
    //       } else if (this.getValue(a, prop) > this.getValue(b, prop)) {
    //         return -1;
    //       }
    // return 0;
    //     };
    //   };

    sortArrByOrder = (prop, order) => {
        console.log(order)
        if (order === "ASC")
            return function (a, b) {
                console.log('ASCENDING')

                let date1 = new Date(a.order_book.createdAt)
                let date2 = new Date(b.order_book.createdAt)


                return date1 - date2
            }

        return function (a, b) {

            console.log('DESCENDING')

            let date1 = new Date(a.order_book.createdAt)
            let date2 = new Date(b.order_book.createdAt)


            return date2 - date1

        }
    }
    onPressSortByOrder = (colName, sort) => {

        this.props.sortOrderByOrder(colName, sort).then((res) => {
            console.log(res.content)
            if (res.status == true) {
                if (colName == "createdAt") {
                    this.setState({
                        orderList: res.content,
                        sortByDate: !this.state.sortByDate
                    })
                } else if (colName == "Reference_No") {
                    this.setState({
                        orderList: res.content,
                        sortByReference: !this.state.sortByReference
                    })
                } else if (colName == "Payment_Status") {
                    this.setState({
                        orderList: res.content,
                        sortByStatus: !this.state.sortByStatus
                    })
                }

                // if (colName == "Name") {
                //     this.setState({
                //         orderList: res.content,
                //         sortByName: !this.state.sortByName
                //     })
                // }

            }
            else {
                alert(res)
            }
        }).catch((err) => {
            console.log(err)

        })

    }

    onSelectChange = (e, type) => {
        this.setState({ [e.target.name]: e.target.value }, () => {
            if (type == 'Earning') {
                this.props.getTotalEarning(this.state.dayEarning).then((res) => {
                    console.log()
                    if (res.content[0]?.order_book?.total_amount) {
                        this.setState({
                            totalearning: res.content[0]?.order_book?.total_amount,
                        })

                    }
                    else {
                        this.setState({
                            totalearning: '',
                        })
                        //alert(res)
                        return
                    }
                }).catch((err) => {
                    console.log(err)

                })
            } else if (type == 'Pending') {
                this.props.getTotalPending(this.state.dayPending).then((res) => {
                    console.log(res.content)
                    if (res.content[0]?.order_book?.total_amount) {
                        this.setState({
                            pendingTotal: res.content[0]?.order_book?.total_amount,
                        })

                    }
                    else {
                        //alert(res)
                        this.setState({
                            pendingTotal: '',
                        })
                    }
                }).catch((err) => {
                    console.log(err)

                })
            }

        })
    }


    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.onSearch(e.target.value)
        })
    }
    onSearch = async (searchStr) => {

        if (!searchStr) {
            this.setState({ OrderListFiltered: [] })
            return
        }
        let { orderList } = this.state
        console.log(orderList)
        let fiteredList = orderList.filter((item) => {
            if (item?.book?.Name.toLowerCase().includes(searchStr.toLowerCase()) || item?.order_book?.Payment_Method.toLowerCase().includes(searchStr.toLowerCase()) || item?.Order_ID.toLowerCase().includes(searchStr.toLowerCase()))
                return true
            return false
        })
        this.setState({
            currentPage: 1
        });
        console.log(fiteredList)
        this.setState({ OrderListFiltered: fiteredList })
    }
    onClickSearch = () => {
        this.props.searchBook(this.state.search).then((res) => {
            console.log(res.content)
            if (res.status == true) {
                this.setState({
                    orderList: res.content,
                })


            }
            else {
                alert(res)
            }
        }).catch((err) => {
            console.log(err)

        })

    }
    onSort = (name, order) => {
        console.log("ORDER : " + order)
        console.log("NAME : " + name)

        this.setState({ ["SORT" + name]: order })
        let { orderList, OrderListFiltered } = this.state
        let orderListSorted = orderList.sort(this.sortArrByOrder(name, order))
        let OrderListFilteredSorted = OrderListFiltered.sort(this.sortArrByOrder(name, order))

        this.setState({ orderList: orderListSorted, OrderListFiltered: OrderListFilteredSorted })

    }
    getBetweenDate = () => {
        const { orderList, OrderListFiltered, fromDate, toDate } = this.state
        let start = new Date(fromDate).getTime();
        let end = new Date(toDate).getTime();
        console.log(orderList)

        var orderListSorted = orderList.filter(d => {
            var time = new Date(d.createdAt).getTime();
            return (start < time && time < end);
        });
        var OrderListFilteredSorted = OrderListFiltered.filter(d => {
            var time = new Date(d.createdAt).getTime();
            return (start < time && time < end);
        });
        console.log('betweenList', orderListSorted)
        this.setState({ orderList: orderListSorted, OrderListFiltered: OrderListFilteredSorted })

    }
    handleClick = (type) => {
        if (type === 'next') {
            this.setState({
                currentPage: this.state.currentPage + 1
            });
        } else if (type === 'previous') {
            this.setState({
                currentPage: this.state.currentPage - 1
            });
        }

    }


    getTotalEarning = (list) => {
        // if(list.length > 0){
        //     var earning = 0
        //     for(var i = 0 ; i < list.length; i++){
        //         earning = earning + parseInt(list[i].order_book?.Amount)
        //     }
        //     console.log(earning) 
        //     this.setState({earning: earning})           
        // }
        console.log('Total Earning', list)
        if (list.length > 0) {
            var earning = 0
            var earningINPKR = 0
            var earningINUSD = 0
            var cleared = 0
            for (var i = 0; i < list.length; i++) {
                
                if(list[i]?.Status == 'Cleared') {
                    console.log(list[i]?.Status)
                    if(list[i].order_book.currency == 'PKR'){
                        cleared = cleared + parseInt(list[i]?.clearingAmount)
                    }
                    else{
                        cleared = cleared + parseInt(list[i]?.clearingAmount * list[i].order_book.dollarRate)
                    }
                }

                if (list[i]?.order_book?.currency == 'PKR') {
                    earningINPKR = earningINPKR + parseInt(list[i].clearingAmount)
                } else if (list[i]?.order_book?.currency == 'USD') {
                    earningINUSD = earningINUSD + parseInt(list[i].clearingAmount * list[i].order_book.dollarRate)
                }
                
                
                // if (list[i]?.order_book?.Payment_Method === "PayPal") {
                //     earning = earning + parseInt(list[i].book?.Price_USD * 150)
                // }
                // else {

                //     earning = earning + parseInt(list[i].book?.Price)
                // }
            }
        }
        console.log('Total Earning IN PKR', earningINPKR)
        console.log('Total Earning IN USD', earningINUSD)
        console.log('Total Earning', earning)
        console.log('Total Cleared', cleared)
        this.setState({ earning: earningINPKR + earningINUSD , cleared : cleared })




    }

    renderTableRows = (list) => {
        console.log('list', list)
        if (list?.length < 1) {

            return <tr>
                <td class="text-center" ><b className="table-text"> No Result</b>

                </td>
            </tr>
        }
        var str = ''
        var str2 = ''
        console.log('LIST', list)
        return list.map((item, i) =>

            <tr>

                {console.log(item?.order_book?.createdAt)}
                {/* <td>{item.Book_ID}</td> */}

                <td><img src={item.book?.Image} width="50px" /></td>

                <td>{item.book?.Name}</td>
                <td>{item?.Order_ID}</td>

                {/* <td><Moment format="DD-MM-YY HH:mm:ss">{item?.order_book?.createdAt}</Moment></td> */}

                <td>
                    <Moment format="DD-MM-YY HH:mm:ss">{new Date(item?.order_book?.createdAt.slice(0, -1))}</Moment>
                </td>


                {/* <td>{item?.order_book?.Payment_Method == "PayPal" ? item?.book?.Price_USD * 150 : item?.book?.Price}</td> */}
                {/* <td>{item?.bookAmount !== null ? item?.bookAmount : 'Not Decided'}</td> */}
                <td>
                    {
                        item?.order_book.currency == "PKR" ? (
                            item?.bookAmount + 'PKR'
                        ) : (
                            <span>
                                {
                                    parseInt(item?.bookAmount * item?.order_book.dollarRate) + 'PKR '
                                }
                                <span style={{ color: '#adb5bd' }}>{`(${`$` + item?.bookAmount})`}</span>
                            </span>
                        )
                    }
                </td>


                <td>
                    <div class={item?.order_book?.Payment_Status == 'Cleared' ? "table-badge-publish" : item?.order_book?.Payment_Status == 'Pending' ? "table-badge-review" : "table-badge-blocked"}>
                        <label className="badge-label" checked>
                            {item?.order_book?.Payment_Status}
                        </label>
                    </div>
                </td>
                <td>
                    <Box
                        className='myBox'
                        sx={
                            {
                                bgcolor: `${item?.Status == 'Cleared' ? '#0E7542' : '#FAC85B'}`,
                            }
                        }
                    >
                        <label className="badge-label2">
                            {
                                item?.Status == 'Cleared' ? (
                                    'Paid'
                                ) : (
                                    item?.Status == 'Published' ? (
                                        'Unpaid'
                                    ) : (
                                        item?.Status == null ? (
                                            'Unpaid'
                                        ) : (
                                            item?.Status
                                        )
                                    )
                                )
                            }
                        </label>
                    </Box>
                </td>
                {/* <td>
                    {item?.order_book?.Reference_No}
                </td> */}
                <td>
                    {
                        item?.Reference_No ? item.Reference_No : 'No Payment Yet'
                    }
                </td>




            </tr>
        )
    }


    togglecongratulationModal = () => {
        this.setState({ CongratulationModal: !this.state.CongratulationModal })
    }




    handlecongratulationClosemodal = (e) => {
        this.setState({ CongratulationModal: !this.state.CongratulationModal })

    }
    handleChange(event) {
        if (event.target.value == 1) {
            this.setState({ CongratulationModal: !this.state.CongratulationModal })
        }


    }
    onClickBottomBar = (val) => {
        this.setState({
            activeTab: val
        })
    }
    render() {

        const { isLoading, orderList, currentPage, todosPerPage, OrderListFiltered } = this.state;

        if (isLoading) {
            return (
                // <div className="loader-large"></div>
                <LottieAnimation />
            )
        }


        let printList = this.state.search ? OrderListFiltered : orderList

        const indexOfLastTodo = currentPage * todosPerPage;
        const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
        const currentTodos = printList.slice(indexOfFirstTodo, indexOfLastTodo);

        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(printList.length / todosPerPage); i++) {
            pageNumbers.push(i);
        }


        return (
            <div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 managebookContainer">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0">
                                <p className="poppins_semibold managebookheading">Selling History</p>
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0  mt-4">

                                <div className="row">
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6  ">
                                        <div className="SellingHistoryTopBarCard">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">

                                                <p className="EaringRs">{this.state.earning == 0 ? '0.00 RS' : this.state.earning + '.00 RS'}</p>
                                                <p className="totalEaring">Total Earning</p>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                                                <div className="row ">
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6  ">
                                        <div className="SellingHistoryTopBarCard">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">

                                                <p className="PendingRs">{this.state.cleared == 0 ? '0.00 RS' : this.state.cleared + '.00 RS'}</p>
                                                <p className="totalEaring">Total Cleared</p>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                                                <div className="row ">
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6  ">
                                        <div className="SellingHistoryTopBarCard">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">

                                                <p className="PendingRs colorPending">{this.state.earning - this.state.cleared + '.00 RS'}</p>
                                                <p className="totalEaring">Total Pending</p>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                                                <div className="row ">
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    {/* <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6   ">
                                        <div className="SellingHistoryTopBarCard">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">

                                                <p className="PendingRs">{this.state.pendingTotal == ''? '0.00 RS' : this.state.pendingTotal + '.00 RS'}</p>
                                                <p className="totalEaring">Pending Amounts</p>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                                                <div className="row ">
                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-5 ">
                                                        <p className="viewtext">View all</p>
                                                    </div>

                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-7 ">
                                                    <select className="select_days" name="dayPending" onChange={(e) => this.onSelectChange(e,'Pending')}>
                                                            <option value='7'>7days</option>
                                                            <option value='14'>14days</option>
                                                            <option value='30'>30days</option>

                                                        </select>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div> */}

                                </div>
                            </div>






                        </div>

                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3 managebookContainer">

                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  ">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-4 AllbookContainer ">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">


                                                <p className="Allbook-heading mb-0">History</p>
                                                <p className="allbooktext mb-0">All selling History</p>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12  ">
                                                <div className="row">
                                                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-4 col-4 "></div>
                                                    <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 text-right ">

                                                        <input className="search_input " placeholder="search here" name="search" onChange={this.onChange}></input>
                                                        <img className="searchicon" src={searchicon} />
                                                        <button className="allbook-search-btn" onClick={() => this.onSearch(this.state.search)}>search</button>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-3 AllbookContainer ">
                                        <div className="table-responsive mt-4 checkout_container">
                                            <table className="table table-hover thead-primary">
                                                <thead>
                                                    <tr>

                                                        {/* <th scope="col table_header poppins_medium">Book ID <img className="dropicon" src={Polygon}></img> </th> */}

                                                        <th scope="col table_header poppins_medium">Book Title </th>
                                                        {this.state.sortByName ? (
                                                            <th scope="col table_header poppins_medium">Book Name   </th>
                                                        ) : (
                                                            <th scope="col table_header poppins_medium">Book Name  </th>
                                                        )}
                                                        <th scope="col table_header poppins_medium">Order ID
                                                        </th>
                                                        {this.state["SORT" + "createdAt"] === "DESC" ? (
                                                            <th onClick={(e) => this.onSort('createdAt', 'ASC')} scope="col table_header poppins_medium">Date & Time
                                                                <span><i class="fa fa-caret-down" aria-hidden="true"></i></span>                                                           {/* <img className="dropicon" src={Polygon} onClick={() => this.onPressSortByOrder('createdAt', 'ASC')}></img>   */}
                                                            </th>
                                                        ) : (
                                                            <th onClick={(e) => this.onSort('createdAt', 'DESC')} scope="col table_header poppins_medium">Date & Time
                                                                <span><i class="fa fa-caret-up" aria-hidden="true"></i></span>                                                                  {/* <img className="dropicon" src={Polygon} onClick={() => this.onPressSortByOrder('createdAt', 'DESC')}></img>  */}
                                                            </th>
                                                        )}
                                                        {this.state.sortByPrice ? (
                                                            <th scope="col table_header poppins_medium">Amount  </th>
                                                        ) : (
                                                            <th scope="col table_header poppins_medium">Amount   </th>

                                                        )}
                                                        {this.state.sortByStatus ? (
                                                            <th scope="col table_header poppins_medium">Payment Status </th>
                                                        ) : (
                                                            <th scope="col table_header poppins_medium">Payment Status  </th>
                                                        )}
                                                        {this.state.sortByStatus ? (
                                                            <th scope='col table_header poppins_medium'>Author Payment Status</th>
                                                        ) : (
                                                            <th scope='col table_header poppins_medium'>Author Payment Status</th>
                                                        )
                                                        }
                                                        {this.state.sortByStatus ? (
                                                            <th scope="col table_header poppins_medium">Reference No.  </th>
                                                        ) : (
                                                            <th scope="col table_header poppins_medium">Reference No.   </th>

                                                        )}

                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {this.state.orderList.length > 0 && this.renderTableRows(currentTodos)}

                                                </tbody>

                                            </table>

                                        </div>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  ">
                                            <div className="row">

                                                <div className=" col-12  text-center">
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  pb-3">

                                                        <div className="row">
                                                            <div className="col-xl-3 col-lg-2 col-md-2 col-sm-2 col-2 ">


                                                                <button className="navbtn" onClick={(e) => this.handleClick('previous')} disabled={currentPage === 1 ? true : false}>← Previous</button>
                                                            </div>
                                                            <div className="col-xl-6 col-lg-8 col-md-8 col-sm-8 col-8  pb-3">

                                                                {/* <button className="roundbtn">1</button>
                                                        <button className="roundbtn"> 2</button>
                                                        <button className="roundbtn">3</button>
                                                        <button className="roundbtn">4</button>
                                                        <button className="roundbtn">5</button> */}
                                                                {/* <p className="allbooktext mb-0">{this.state.currentPage + '/' + pageNumbers.length}</p> */}
                                                                <label className="poppins_bold">{this.state.currentPage}</label>
                                                                <label className="poppins_regular ml-3 mr-3">out of</label>
                                                                <label className="poppins_bold">{pageNumbers.length}</label>

                                                            </div>
                                                            <div className="col-xl-3 col-lg-2 col-md-2 col-sm-2 col-2 ">

                                                                <button className="navbtn" onClick={(e) => this.handleClick('next')} disabled={this.state.currentPage === pageNumbers.length ? true : false}>Next →</button>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>


                                            </div>



                                        </div>

                                    </div>

                                </div>


                            </div>


                        </div>

                    </div>


                </div>




            </div>

        )



        //                     <div>
        //               <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

        //                   <div className="row">
        //                       <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 managebookContainer">
        //                              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0">
        //                                  <p className="poppins_semibold managebookheading">Selling History</p>
        //                              </div>
        //                              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0  mt-4">

        //                              <div className="row">
        //                                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6  ">
        //                                    <div className="SellingHistoryTopBarCard">
        //                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">

        //                                                  <p className="EaringRs">{this.state.earning == 0? '0.00 RS' : this.state.earning + '.00 RS'}</p>
        //                                                  <p className="totalEaring">Total Earning</p>
        //                                              </div>
        //                                              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
        //                                                  <div className="row ">

        //                                               </div>

        //                                              </div>
        //                                          </div>

        //                                      </div>

        //                               </div>
        //                           </div>



        //                           </div>

        //                                   </div>
        //                               </div>




        //   <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3 mb-3 managebookContainer">
        //         <DataTable
        //     //    orderList={this.state.orderList}
        //     orderList={this.state.search ? OrderListFiltered :orderList}
        //             onChange={this.onChange}
        //             onSearch={this.onSearch}
        //             search={this.state.search}
        //         />

        //        </div>
        //         </div>

        //         )
    }

}

SellingHistory.propTypes = {

};


const mapStateToProps = state => ({

});

const mapDispatchToProps = ({
    getAllOrder,
    sortOrderByBook,
    sortOrderByOrder,
    searchBook,
    getTotalEarning,
    getTotalPending,
})
export default connect(mapStateToProps, mapDispatchToProps)(SellingHistory);
