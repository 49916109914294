
import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Moment from "react-moment";
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SearchField from "../SearchField/SearchField";
import { Grid } from "@mui/material";


function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};




function createData(OrderID, BookDetails,DateTime, Amount, BuyerID , Status, BookImage,AuthorName) {
  return {
    OrderID, 
    BookDetails,DateTime, Amount, BuyerID , Status,BookImage,AuthorName,
    
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.OrderID}
        </TableCell>
        <TableCell >{row.BookDetails}</TableCell>
        <TableCell ><Moment format="DD-MM-YY HH:mm:ss">{row.DateTime}</Moment></TableCell>
        <TableCell >{row.Amount}</TableCell>
        <TableCell>{row.BuyerID}</TableCell>
        <TableCell>{row.Status}</TableCell>
        <TableCell><MoreHorizIcon/></TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{
          display: 'flex',
          alignItems: 'center',
          width: 'fit-content',
          border: (theme) => `1px solid ${theme.palette.divider}`,
          borderRadius: 1,
          bgcolor: 'background.paper',
          color: 'text.secondary',
          // '& svg': {
          //   m: 1.5,
          // },
          // '& hr': {
          //   mx: 0.5,
          // },
        }}>
             <Grid><img src={row.BookImage} width="75px" height="100px"/></Grid>
             <Grid container
              direction="column">
           <Typography > {row.BookDetails}</Typography>
            <Typography variant="caption">{`By: ${row.AuthorName}`}</Typography>
            <Typography variant="caption">Date:<Moment format="DD-MM-YY HH:mm:ss">{row.DateTime}</Moment></Typography>
             </Grid>
             <Grid></Grid>
             <Grid></Grid>
             <Grid></Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    OrderID: PropTypes.string.isRequired, 
    BookDetails : PropTypes.string.isRequired, 
    BuyerID : PropTypes.string.isRequired , 
    DateTime : PropTypes.any.isRequired, 
    Amount : PropTypes.string.isRequired, 
    Status : PropTypes.string.isRequired,
    BookImage:PropTypes.string.isRequired,
    AuthorName:PropTypes.string.isRequired,
    
    // history: PropTypes.arrayOf(
    //   PropTypes.shape({
    //     amount: PropTypes.number.isRequired,
    //     customerId: PropTypes.string.isRequired,
    //     date: PropTypes.string.isRequired,
    //   })
    // ).isRequired,
  }).isRequired,
};

// const rows = [
//   createData("Frozen yoghurt", 159, 6.0, 24, 4.0, 3.99),
//   createData("Ice cream sandwich", 237, 9.0, 37, 4.3, 4.99),
//   createData("Eclair", 262, 16.0, 24, 6.0, 3.79),
//   createData("Cupcake", 305, 3.7, 67, 4.3, 2.5),
//   createData("Gingerbread", 356, 16.0, 49, 3.9, 1.5),

// ];

export default class DataTable extends React.Component{
    constructor(props) {
        super(props);
        this.state = {

          orderList:[],
          page: 0,
          rowsPerPage: 10,
        }
      
      }
        
        componentDidMount(){
         this.setState({
          orderList: this.props.orderList
       })
        }
 
        componentDidUpdate = function (prevProps, prevState, snapshot) {
          if (this.props.orderList !== prevProps.orderList) {
            this.setState({
              orderList: this.props.orderList,
            });
          }
        };

   handleChangePage = (event, newPage) => {
    this.setState({page:newPage});
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({rowsPerPage:parseInt(event.target.value, 10), page:0});
    
  };
        render()
        {
          console.log(this.state.orderList)
          const rows=[];
          
          this.state.orderList.map
          ((item) =>
          rows.push(createData(item?.Order_ID,
            item.book?.Name,
            new Date(item?.order_book?.createdAt.slice(0,-1)),
            // item?.order_book?.Amount,
            item?.order_book?.Payment_Method == "PayPal" ? item?.book?.Price_USD * 150 : item?.book?.Price,
            item?.order_book?.Buyer_ID,
            item?.order_book.Payment_Status,
            item?.book.Image,
            item?.book?.Author_Name)))
         
  return (
    <Box >
      <Paper>
      <Grid
            container
            sx={{
              display: "flex",
              p:3 ,
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h4">Order History</Typography>
            <SearchField
              onChange={this.props.onChange}
              onClickSearch={this.props.onClickSearch}
              onSearch={this.props.onSearch}
              search={this.props.search}
            />
          </Grid>
    <TableContainer>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>ORDER ID</TableCell>
            <TableCell >BOOK DETAILS</TableCell>
            <TableCell >DATE & TIME</TableCell>
            <TableCell >AMOUNT</TableCell>
            <TableCell >BUYER ID</TableCell>
            <TableCell >STATUS</TableCell>
            <TableCell >ACTION</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(this.state.rowsPerPage > 0
            ? rows.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
            : rows
          ).map((row) => (
            <Row  row={row} />
          ))}
        </TableBody>
        
       
        
      </Table>
      
    </TableContainer>
    <TablePagination
               component="div"
               count={rows.length}
               page={this.state.page}
               onPageChange={this.handleChangePage}
               rowsPerPage={this.state.rowsPerPage}
              rowsPerPageOptions={[10, 25, { label: 'All', value: -1 }]}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
              colSpan={2}
             
              
              
             
              // SelectProps={{
              //   inputProps: {
              //     'aria-label': 'rows per page',
              //   },
              //   native: true,
              // }}
              
             
              ActionsComponent={TablePaginationActions}
            />
    </Paper>
    </Box>
  );}
}
