
import React, { Component } from 'react';

import { connect } from 'react-redux';

import epubuploadicon from '../../assets/images/epubupload.svg'

import imageplaceholder from '../../assets/images/imageplaceholder.png';


import { initiateCheckout, getForeeCheckoutURL } from 'foree-checkout';

import PayWithPayPal from './PayWithPayPal'

import { uploadBook, getConversionPrice,uploadImage,createBookConverReq } from '../../store/actions/bookAction';




var cx = require('classnames');





class CreateBook extends Component {
    constructor(props) {
        super(props);
        this.state = {

            errors: {},
            serverError: {},
            isLoading: false,
            book_title: "",
            author_name: "",
            book_url: "",
            cover_url: "",
            price: "",
            priceList: [],
            original_book_name: "",
            Image:"",
            payment_option:"",
            isPaypal: false,

        };
        this.handleChange = this.handleChange.bind(this);

    }

    componentDidUpdate(prevProps, prevState, snapshot) {


    }

    onFileChange(event) {

        event.preventDefault();

        let reader = new FileReader();
        let file = event.target.files[0];

        reader.onloadend = () => {
            this.setState({
                file: file,
                //epub: reader.result
            }, () => {
                this.uploadBook() 
            });

        }
        reader.readAsDataURL(file)
    }
    uploadBook() {
        const payload = new FormData();
        //payload.append('imageType', 'RESTAURENT_OWNER')
        payload.append('book', this.state.file);
        this.setState({ isUploading: true })
        this.props.uploadBook(payload).then((res) => {
            console.log(res.content)
            this.setState({ isUploading: false })
            if (res.content.length > 0) {
                console.log(res.content[0].url)
                this.setState({ book_url: res.content[0].url, original_book_name: res.content[0].originalname })
            }
        }).catch((err) => {
            this.setState({ isUploading: false })
            if (err.hasOwnProperty('validation')) {
                err.validation.map(obj => {
                    this.setState({ errors: {Book_Url: obj["msg"]} });                    
                });

 
            } 


        })

    }


    onImageChange(event) {

        event.preventDefault();

        let reader = new FileReader();
        let file = event.target.files[0];

        reader.onloadend = () => {
            this.setState({
                file: file,
                image: reader.result
            }, () => {
                this.uploadImage();
            });

        }

        reader.readAsDataURL(file)

    }
    uploadImage() {
        const payload = new FormData();
        payload.append('imageType', "BOOK")
        payload.append('photo', this.state.file);
        this.setState({ isUploading: true })
        this.props.uploadImage(payload).then((res) => {
            console.log(res.content)
            this.setState({ isUploading: false })
            if (res.content.length > 0) {
                console.log(res.content[0].url)
                    this.setState({ Image: res.content[0].url })
            }
        }).catch((err) => {
            this.setState({ isUploading: false })
            if (err.hasOwnProperty('validation')) {
                err.validation.map(obj => {
                    this.setState({ errors: {Book_Cover: obj["msg"]} });                    
                });

 
            }

        })

    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
      
    }

    componentDidMount() {

        this.props.getConversionPrice().then((res) => {
            console.log(res)
            this.setState({
                priceList: res.content,
            })

        }).catch((err) => {
            console.log(err)

        })
    }
    togglecongratulationModal = () => {
        this.setState({ CongratulationModal: !this.state.CongratulationModal })
    }




    handlecongratulationClosemodal = (e) => {
        this.setState({ CongratulationModal: !this.state.CongratulationModal })

    }
    handleChange(event) {
        if (event.target.value == 1) {
            this.setState({ CongratulationModal: !this.state.CongratulationModal })
        }


    }
    onClickBottomBar = (val) => {
        this.setState({
            activeTab: val
        })
    }

    onClickPayment = (data) => {
        console.log(data)
        if (data == "Easy_paisa") {
            //console.log(sum)
            localStorage.setItem('Price', this.state.sum);
            var firstPart = (Math.random() * 46656) | 0;
            var secondPart = (Math.random() * 46656) | 0;
            firstPart = ("000" + firstPart.toString(36)).slice(-3);
            secondPart = ("000" + secondPart.toString(36)).slice(-3);
            const id = firstPart + secondPart;
            //localStorage.setItem('Price', this.state.book?.Price);
            this.props.history.push("/confirmpayment", { book: this.state.book, sum: this.state.sum, cart: this.props.cart, Order_ID: id })
            return
        }
        if (data == "Paypal") {
            //this.props.history.push("/confirmpayment", { book: this.state.book })
           
            var Order_ID = Math.floor(Math.random() * 900000) + 100000
            //this.createOrderPaypal(Order_ID)

            this.setState({ isPaypal: true })


            return
        }
        if (data == "MasterCard") {

            var Order_ID = Math.floor(Math.random() * 900000) + 100000

            var data = {
                // 'key': '49d6dbb2-2ca3-448d-93b1-f30b5bf39b3a',
                'key': 'e1ed5823-4a31-48c4-a214-f97970159846',
                'amount': '1',
                //'amount': '1',

                'is_generated': 0,
                'reference_number': Order_ID,
                'customer_email_address': this.props.user?.Email,
                'customer_phone_number': '03092283803',
                'consumer_name': this.props.user?.Full_Name
            }

            initiateCheckout(data, true)
            //this.createOrder(Order_ID)

        }
        return
        // var data = {
        //     'amount': '1',
        //     'storeId':'73507',
        //     'orderRefNum':'500',
        //     'expiryDate':'20210320 174000',
        //     'postBackURL':'http://localhost',
        //     'autoRedirect':"0",
        //     'mobileNum':'03313844515'
        // }
        // this.props.getEasyPay({
        //     'amount': '1',
        //     'storeId':'73507',
        //     'orderRefNum':'500',
        //     'expiryDate':'20210320 174000',
        //     'postBackURL':'http://localhost',
        //     'autoRedirect':"0",
        //     'mobileNum':'03313844515'
        // }).then((res) => {
        //     console.log(res)
        //     return
        //     // if (res.status == true) {
        //     //     this.setState({
        //     //         newBookList: res.content,
        //     //     })

        //     // }
        //     // else {
        //     //     alert(res)
        //     // }
        // }).catch((err) => {
        //     console.log(err)

        // })

    }

    onAddBookConverReq = () => {
        // this.setState({ errors: {}, serverError: {} })
        var addBookData = {
            "Book_Url": this.state.book_url,
            "Book_Title": this.state.book_title,
            "Author_Name": this.state.author_name,
            "Book_Cover": this.state.Image,
            "Price_ID": this.state.price,    
        }
        // this.setState({ isLoading: true })
        // var msg = "Succsessfully Add item";
        // if (this.state.item_id != null) {
        //   msg = "Succsessfully Update item";
        // }
        this.props.createBookConverReq(addBookData).then((res) => {
            console.log(res)
            if (res.status) {
                console.log(res)
                // this.setState({
                //     Book_ID: res.content[0] && res.content[0].Book && res.content[0].Book.Book_ID,
                //     activeTab: this.state.activeTab + 1,
                // })
                 this.props.history.push('/confirmpayment',{result:res.content[0]?.Request, Price:this.state.priceList[this.state.price - 1]?.Price});
            }
        }).catch((err) => {
            var validationError = {}
            var serverError = []
            console.log(err.hasOwnProperty('validation'))
            if (err.hasOwnProperty('validation')) {
                err.validation.map(obj => {
                    if (obj.hasOwnProperty('param')) {
                        validationError[obj["param"]] = obj["msg"]
                    } else {
                        serverError = [...serverError, obj]
                    }
                });
                this.setState({ errors: validationError });
                this.setState({ serverError: serverError });
            } else {
                this.setState({ serverError: [{ "msg": "server not responding" }] })
            }
        })
    }
    render() {

        const { isLoading ,errors, isPaypal} = this.state;


        if (isLoading) {
            return (
                <div className="loader-large"></div>
            )
        }

        if (isPaypal) {

            return (
                <PayWithPayPal
                    total={this.state.sum}
                    //total={1}
                    //item={book.Name}
                    updateOrder={this.props.updateOrder}
                    Order_ID={this.state.Order_ID}
                    //Book_ID={this.state.book?.Book_ID}
                    putBookInLibrary={this.props.putBookInLibrary}
                    cart={this?.props?.location?.state?.cart}
                    history={this.props.history}
                >

                </PayWithPayPal>
            )
        }

        return (
            <div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 managebookContainer">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0">
                                <p className="poppins_semibold managebookheading">Create a new eBook</p>
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                                <div className="bookDescriptionCard">

                                    <p className="manageBookTopBarCard-Heading poppins_medium   mt-2 mb-0">Converting your Book to an eBook</p>
                                    <label className="poppins_light  manageBookTopBarCard-Text mt-2 mb-0">Please enter required information and details</label>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4">
                                        <div className="row">
                                            <div className=" col-xl-2 col-lg-2 col-md-3 col-sm-12 col-4 vertical_center text-right">
                                                <p className="manageBookTopBarCard-Heading poppins_medium   mb-0">Book Title</p>
                                            </div>
                                            <div className=" col-xl-4 col-lg-4 col-md-4 col-sm-12 col-8 vertical_center4">
                                                <input className="col-xl-12 managebookInput" name="book_title" onChange={this.onChange} ></input>
                                                {errors.Book_Title && <div className="invaliderrorLogin">{errors.Book_Title}</div>}

                                            </div>
                                            <div className=" col-xl-2 col-lg-2 col-md-2 col-sm-12 col-4 vertical_center text-right">
                                                <p className="manageBookTopBarCard-Heading poppins_medium   mb-0">Book Author</p>
                                            </div>
                                            <div className=" col-xl-4 col-lg-4 col-md-3 col-sm-12 col-8  vertical_center">
                                                <input className="col-xl-12 managebookInput" name="author_name" onChange={this.onChange} ></input>
                                                {errors.Author_Name && <div className="invaliderrorLogin">{errors.Author_Name}</div>}




                                            </div>

                                        </div>

                                    </div>


                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                                        <div className="row">

                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                                                    <div className="row">
                                                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 ">
                                                        </div>
                                                        <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 ">
                                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ml-2 ">
                                                                <div className="EpubFile_roundDiv">
                                                                    <label className="poppins_bold EpubFile_roundDiv_text">1</label>
                                                                </div>

                                                            </div>

                                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ml-4 ">
                                                                <p className="manageBookTopBarCard-Heading poppins_medium   mt-2 mb-0">Upload Book</p>
                                                                <label className="poppins_light  manageBookTopBarCard-Text mt-2 mb-0">MS Word, Adobe InDesign and InPage Urdu file.</label><br></br>

                                                                <label className=" mt-2 mb-0"><img src={epubuploadicon}></img> <label className="poppins_light  manageBookTopBarCard-Text ml-2"> {this.state.original_book_name === '' ? 'No File Uploaded' : this.state.original_book_name}  </label></label> <br></br>
                                                                <button className="col-xl-5 poppins_semibold uploadbtn" onClick={(e) => this.upload.click()}> UPLOAD FILES<input id="myInput2" type="file" onChange={(event) => this.onFileChange(event)} name="book_url" ref={(ref) => this.upload = ref} style={{ display: 'none' }} /></button>

                                                            </div>




                                                        </div>

                                                    </div>
                                                </div>
                                                {errors.Book_Url && <div className="invaliderrorLogin">{errors.Book_Url}</div>}


                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                                                    <div className="row">
                                                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 ">
                                                        </div>
                                                        <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 ">
                                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ml-2 ">
                                                                <div className="EpubFile_roundDiv">
                                                                    <label className="poppins_bold EpubFile_roundDiv_text">2</label>
                                                                </div>

                                                            </div>

                                                             <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ml-3 ">
                                                                        <p className="manageBookTopBarCard-Heading poppins_medium   mt-2 mb-0">Upload Cover</p>
                                                                        <label className="poppins_light  manageBookTopBarCard-Text mt-2 mb-0">Upload a cover you already have (JPG/TIF only)</label><br></br>
                                                                        <div className=" col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 vertical_center4">
                                                                            <div className="row">
                                                                                <div className=" col-xl-5 col-lg-5 col-md-6 col-sm-6 col-12  mt-4 vertical_center4">
                                                                                    <div className="">
                                                                                        <img src={this.state.Image === '' ? imageplaceholder : this.state.Image} style={{ width: '100%', borderRadius: '15px' }}></img>
                                                                                        {/* <p className="bookimg-placeholderText">No Cover Uploaded</p> */}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-xl-7 col-lg-7 col-md-6 col-sm-6 col-12 mt-4">
                                                                                    {/* <button className="col-xl-12 poppins_semibold uploadbtn">UPLOAD FILE</button> */}
                                                                                    <button className="col-xl-12 poppins_semibold uploadbtn" onClick={(e) => this.uploads.click()}>UPLOAD FILE<input id="myInput" type="file" onChange={(e) => this.onImageChange(e)} name="book_url" ref={(ref) => this.uploads = ref} style={{ display: 'none' }} /></button>

                                                                                    <p className="mb-0 poppins_light  manageBookTopBarCard-Text">Not be more then 2 MB</p>
                                                                                    <p className="mb-0 poppins_light  manageBookTopBarCard-Text">Pixel Size 1645 x 2550 (Recommended)</p>
                                                                                    <p className="mb-0 poppins_light  manageBookTopBarCard-Text">Resolution 150 DPI</p>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>




                                                        </div>

                                                    </div>
                                                </div>
                                                {errors.Book_Cover && <div className="invaliderrorLogin">{errors.Book_Cover}</div>}



                                            </div>


                                        </div>
                                    </div>

                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                                        <div className="row">

                                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 mt-3">

                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                                                    <div className="row">
                                                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 ">
                                                        </div>
                                                        <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 ">
                                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ml-2 ">
                                                                <div className="EpubFile_roundDiv">
                                                                    <label className="poppins_bold EpubFile_roundDiv_text">3</label>
                                                                </div>


                                                            </div>

                                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ml-4 ">
                                                                <p className="manageBookTopBarCard-Heading poppins_medium   mt-2 mb-0">EPUB Conversion Charges</p>
                                                                <label className="poppins_light  manageBookTopBarCard-Text mt-2 mb-0">Select as per your book pages</label><br></br>

                                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  ml-2 mr-2">
                                                                    {this.state.priceList.map((item, index) => (
                                                                        <p class="mt-3">
                                                                            <input type="radio" id={index} name="radio-group" name="price" onChange={this.onChange} value={index+1} />
                                                                            <label className="poppins_regular ml-3" for={index}>{item.Price + " PKR"}</label>
                                                                            <label className="poppins_regular ml-3" >{item.Detail}</label>

                                                                        </p>)
                                                                        
                                                                    )}



                                                                    {/* <p class="mt-3">
                                                                        <input type="radio" id="Easy_paisa" name="radio-group" />
                                                                        <label className="poppins_regular ml-3" for="Easy_paisa">6000 PKR</label>
                                                                        <label className="poppins_regular ml-3" >(200 - 500 Pages Book)</label>

                                                                    </p>


                                                                    <p class="mt-3">
                                                                        <input type="radio" id="Easy_paisa" name="radio-group" />
                                                                        <label className="poppins_regular ml-3" for="Easy_paisa">8000 PKR</label>
                                                                        <label className="poppins_regular ml-3" >(500 - 800 Pages Book)</label>

                                                                    </p> */}


                                                                </div>


                                                                <label className="poppins_light  manageBookTopBarCard-Text mt-2 mb-0"> <label className="poppins_medium  manageBookTopBarCard-Text  mb-0">Note:</label> If you don't find your option contact to our customer support person.</label><br></br>
                                                                {errors.Price_ID && <div className="invaliderrorLogin">{errors.Price_ID}</div>}


                                                            </div>

                                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3 ml-2 ">
                                                                <div className="EpubFile_roundDiv">
                                                                    <label className="poppins_bold EpubFile_roundDiv_text">4</label>
                                                                </div>


                                                            </div>

                                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ml-4 ">
                                                                <p className="manageBookTopBarCard-Heading poppins_medium   mt-2 mb-0">Payment Method</p>
                                                                <label className="poppins_light  manageBookTopBarCard-Text mt-2 mb-0">Select your payment method to purchase the process</label><br></br>

                                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  ml-2 mr-2">
                                                                        <p class="mt-3">
                                                                            <input type="radio" id="Easy_paisa" name="payment_option" onChange={this.onChange} value="Easy_paisa"  />
                                                                            <label className="poppins_regular ml-3" for="Easy_paisa" >Easy Paisa</label>

                                                                        </p>
                                                                        <p class="mt-3">
                                                                            <input type="radio" id="MasterCard" name="payment_option"  onChange={this.onChange} value="MasterCard"  />
                                                                            <label className="poppins_regular ml-3" for="MasterCard" >Forree</label>

                                                                        </p>
                                                                        <p class="mt-3">
                                                                            <input type="radio" id="Paypal" name="payment_option"  onChange={this.onChange} value="Paypal"  />
                                                                            <label className="poppins_regular ml-3" for="Paypal">Paypal</label>

                                                                        </p>
                                                                        



                                                                  


                                                                </div>


                                                              

                                                            </div>


                                                        </div>

                                                    </div>
                                                </div>


                                            </div>








                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt_auto">

                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  ">
                                                    <label className="poppins_light  manageBookTopBarCard-Text mt-2 mb-0">* Note: We will need 3 to 4 working days to prepare the eBook. When your eBook is ready it will be put on your panel. From here you can download and publish your eBook.</label><br></br>
                                                    <button className="col-xl-12 continuebtn" onClick={() => this.onClickPayment(this.state.payment_option)}>Continue & Pay</button>
                                                </div>


                                            </div>


                                        </div>
                                    </div>


                                </div>

                            </div>






                        </div>


                    </div>


                </div>




            </div>

        )
    }

}

CreateBook.propTypes = {

};


const mapStateToProps = state => ({

});

const mapDispatchToProps = ({
    uploadBook,
    getConversionPrice,
    uploadImage,
    createBookConverReq,
})
export default connect(mapStateToProps, mapDispatchToProps)(CreateBook);
