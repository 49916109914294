
import React, { Component } from "react";
import Lottie from "react-lottie";
import lAnimation from "../../assets/lottie/girl-with-books.json"


const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
export default class LottieAnimation extends Component{

    render(){


        return(
        <div>
            <Lottie 
              options={defaultOptions}
              height={400}
              width={400}
            />
          </div>
          )
    }
}