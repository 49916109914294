import React, { Component } from 'react'
import {Oval} from "svg-loaders-react";
export default class SvgLoader extends Component {
  render() {
    return (
      <Oval
      style={{height:20, width:20,stroke:"yellow", objectPosition: "5px 10%"}}
      />
    )
  }
}
