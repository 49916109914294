import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
export default class SearchField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //search: ""
    };
  }
  onChange = (e) => {
    this.props.onChange(e)
  }
  onClickSearch = () =>{
this.props.onClickSearch()
  }
  render(){
    return (
      <Paper
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 270 }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search"
          name="search"
          onChange={this.onChange}
          inputProps={{ 'aria-label': 'search' }}
        />
        <IconButton type="submit" sx={{ p: '10px' }} onClick={() => this.onSearch(this.props.search)} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>
    );
  }
}